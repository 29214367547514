import { GetData } from "contexts/DataContext";

import {
  Cached,
  DarkMode,
  Delete,
  ExitToApp,
  MoreVert,
} from "@mui/icons-material";
import { Divider, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";

import { StorageDialog } from "components/StorageDialog";
import { GetAuthData } from "contexts/TAuthContext";
import { GetColorMode } from "contexts/ThemeContext";

import { GetBasket } from "contexts/BasketContext";
import React from "react";
import { Edit } from "react-feather";

export function useLongPress({
  ms = 2000,
  onClick = () => {},
  onLongPress = () => {},
} = {}) {
  const timerRef = React.useRef(false);
  const eventRef = React.useRef({});

  const callback = React.useCallback(() => {
    onLongPress(eventRef.current);
    eventRef.current = {};
    timerRef.current = false;
  }, [onLongPress]);

  const start = React.useCallback(
    (ev) => {
      ev.persist();
      eventRef.current = ev;
      timerRef.current = setTimeout(callback, ms);
    },
    [callback, ms],
  );

  const stop = React.useCallback(
    (ev) => {
      ev.persist();
      eventRef.current = ev;
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        onClick(eventRef.current);
        timerRef.current = false;
        eventRef.current = {};
      }
    },
    [onClick],
  );

  return React.useMemo(
    () => ({
      onMouseDown: start,
      onMouseLeave: stop,
      onMouseUp: stop,
      onTouchEnd: stop,
      onTouchStart: start,
    }),
    [start, stop],
  );
}

export default function ProfileMenu(props) {
  const { clearCache, clearDataArr } = GetData();
  const { toggleColorMode } = GetColorMode();
  const { setToken, setTokenData } = GetAuthData();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { contextDelete } = GetBasket();
  const [openDialog, setOpenDialog] = React.useState(false);
  const longPressProps = useLongPress({
    // onClick: (ev) => console.log("on click", ev.button, ev.shiftKey),
    onLongPress: () => setOpenDialog(true),
  });

  async function onCacheReset() {
    setAnchorEl(null);
    await clearCache();
    await contextDelete("basket");
    await contextDelete("registration");
    await contextDelete("prices");
    window.location.reload(false);
    // navigate("/");
    // navigate(0);
  }

  async function onClearArray() {
    setAnchorEl(null);

    await clearDataArr();
    // setToken(null);
    setTokenData({});
    // window.location.reload(false);
    // navigate("/");
    // navigate(0);
  }
  return (
    <React.Fragment>
      <Tooltip
        title="Действия"
        // sx={{ display: props?.fromMain ? "none" : undefined }}
      >
        <IconButton
          sx={{
            lineHeight: 1,
            position: "absolute",
            top: "0.5rem",
            right: "0.5rem",
            // display: props?.fromMain ? "none" : undefined,
          }}
          {...longPressProps}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <MoreVert />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        id="demo-customized-menu"
        onClose={() => setAnchorEl(null)}
        open={open}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuItem
          disableRipple
          onClick={() => {
            setAnchorEl(null);
            props.setOpen(true);
          }}
          sx={{ justifyContent: "flex-end", minHeight: "32px" }}
        >
          Изменить
          <Edit />
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={toggleColorMode}
          sx={{ justifyContent: "flex-end", minHeight: "32px" }}
        >
          Изменить тему
          <DarkMode />
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={() => {
            onCacheReset();
          }}
          sx={{ justifyContent: "flex-end", minHeight: "32px" }}
        >
          Очистить кэш
          <Cached />
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={() => {
            onClearArray();
          }}
          sx={{ justifyContent: "flex-end", minHeight: "32px" }}
        >
          Выйти из профиля
          <ExitToApp />
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          disableRipple
          onClick={() => {
            onClearArray();
          }}
          sx={{ justifyContent: "flex-end", minHeight: "32px" }}
        >
          Удалить профиль
          <Delete />
        </MenuItem>
      </Menu>
      <StorageDialog setOpen={setOpenDialog} status={openDialog} />
    </React.Fragment>
  );
}

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import JsonView from "@uiw/react-json-view";
import { GetData } from "contexts/DataContext";
import React from "react";

import { GetBasket } from "contexts/BasketContext";
import { GetCInfo } from "contexts/CompanyContext";
import { GetAuthData } from "contexts/TAuthContext";
import { isObjectEmpty } from "data/functions";
import { BoxFlexRow } from "data/styleGlobals";

export const StorageDialog = (props) => {
  const { dataObj } = GetData();
  const { cInfo } = GetCInfo();
  const { token } = GetAuthData();
  const basketContext = GetBasket();
  const theme = useTheme();
  const [array, setArray] = React.useState({});

  React.useEffect(() => {
    let arr = {};
    if (!isObjectEmpty(dataObj)) arr = { ...arr, ...{ dataObj: dataObj } };
    if (!isObjectEmpty(theme)) arr = { ...arr, ...{ theme: theme } };
    if (!isObjectEmpty(cInfo)) arr = { ...arr, ...{ cInfo: cInfo } };
    if (!isObjectEmpty(basketContext?.basket))
      arr = { ...arr, ...{ basketData: basketContext?.basket } };
    if (!isObjectEmpty(basketContext?.prices))
      arr = { ...arr, ...{ pricesData: basketContext?.prices } };
    if (!isObjectEmpty(token)) arr = { ...arr, ...{ token: token } };
    if (!isObjectEmpty(localStorage))
      arr = { ...arr, ...{ localStorage: localStorage } };
    if (!isObjectEmpty(sessionStorage))
      arr = { ...arr, ...{ sessionStorage: sessionStorage } };
    setArray(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataObj, cInfo, token, theme, basketContext]);

  return (
    <Dialog
      PaperProps={{
        style: {
          width: "90vw",
          maxWidth: "90vw",
          minHeight: "90vh",
        },
      }}
      onClose={() => props?.setOpen(false)}
      open={props.status}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => props?.setOpen(false)}
      >
        Параметры
      </DialogTitle>
      <DialogContent sx={{ padding: 0, overflowY: "scroll" }}>
        {Object.keys(array).map((el, index) => {
          if (!isObjectEmpty(el)) {
            return (
              <Box key={index}>
                <BoxFlexRow
                  key={index}
                  sx={{
                    padding: "0rem 1rem",
                    flexDirection: "column!important",
                    alignItems: "start!important",
                  }}
                >
                  <Typography
                    variant={dataObj?.deviceWidth?.isMobile ? "body2" : "body1"}
                  >
                    {el}
                  </Typography>

                  <JsonView
                    collapsed
                    displayDataTypes={false}
                    value={
                      typeof array[el] === "object"
                        ? array[el]
                        : { [typeof array[el]]: array[el] }
                    }
                  />
                </BoxFlexRow>
                <Divider />
              </Box>
            );
          } else {
            return "";
          }
        })}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          fullWidth
          onClick={(e) => {
            props.setOpen(false);
          }}
          variant="outlined"
        >
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { PhotoCamera } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { GetGrzDecode } from "components/gql/queris/GetGrzDecode";
import { GetUploadUrlQuery } from "components/gql/queris/GetUploadUrl";
import { GetAlertData } from "contexts/AlertContext";
import { GetCInfo } from "contexts/CompanyContext";
import React from "react";
import { uploadFile } from "tools/requests";

export default function DecodeGrz(props) {
  const [grzDecodeQuery] = GetGrzDecode();
  const [queryUpload] = GetUploadUrlQuery();
  const inputRef = React.useRef(null);
  const { setAlert } = GetAlertData();
  const { cInfo } = GetCInfo();

  const handleChange = async function (e) {
    e.preventDefault();
    await changeImageArray(e.target.files);
    e.target.value = null;
    e.stopPropagation();
  };

  async function changeImageArray(array) {
    if (array && array[0]) {
      let { data } = await queryUpload({
        variables: {
          filename: array[0].name,
        },
      });
      if (data?.gibdd_uploadUrl?.fileID) {
        let success = await uploadFile(data?.gibdd_uploadUrl, array[0]);
        if (!success) {
          setAlert("Не удалось загрузить файл для расшифровки", "warning");
        } else {
          let grzData = await grzDecodeQuery({
            variables: {
              fileId: data?.gibdd_uploadUrl?.fileID,
            },
          });
          if (grzData?.data && grzData?.data?.gibdd_grzDecode?.grz) {
            props?.setPlateDecodeData(grzData?.data?.gibdd_grzDecode);
            props?.setPlate(grzData?.data?.gibdd_grzDecode?.grz);
            props?.setPlateText(grzData?.data?.gibdd_grzDecode?.grz);
            props?.checkPlate(grzData?.data?.gibdd_grzDecode?.grz);
          }
          if (grzData?.error) {
            props?.setPlateDecodeData(grzData?.error);
          }
        }
      }
    }
  }

  const find = (cInfo?.channels ?? []).find((el) => {
    if (el?.name === "Gibdd_Sts") return true;
    else return false;
  });

  return (
    <React.Fragment>
      {find && (
        <React.Fragment>
          <input
            accept="image/*"
            alt="Гос. номер"
            id={`input-file-upload-grz`}
            multiple={false}
            onChange={handleChange}
            ref={inputRef}
            style={{ display: "none" }}
            type="file"
          />

          <IconButton
            aria-hidden={false}
            disableRipple
            onClick={(e) => {
              inputRef.current.click();
            }}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <PhotoCamera aria-hidden={false} />
          </IconButton>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

import { useLazyQuery } from "@apollo/client";
import { STS_DECODE } from "components/gql/gql_queries";
import { consoleBeauty } from "data/functions";

export function GetStsDecode() {
  const [refetch, variables] = useLazyQuery(STS_DECODE, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
    },
  });
  return [refetch, variables];
}

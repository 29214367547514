import { Box } from "@mui/material";
import { GetModelQuery } from "components/gql/queris/GetModel";
import { isObjectEmpty } from "data/functions";

import React, { useEffect, useState } from "react";

export default function ImageBlock(props) {
  const [image, setImage] = useState("");
  const [refetch] = GetModelQuery();
  useEffect(() => {
    getModelImage(props?.marka, props?.model);
  }, [props?.model]);

  async function getModelImage(marka, model) {
    if (isObjectEmpty(model)) {
      setImage("");
      return;
    }
    let { data } = await refetch({
      variables: {
        markId: marka?.id,
        modelId: model?.id,
      },
    });
    if (data && data.exch_getModel?.presignURL?.URL) {
      setImage(data.exch_getModel?.presignURL?.URL);
    } else {
      setImage("");
    }
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {image ? (
          <img
            alt={props?.marka + " " + props?.model}
            src={image}
            style={{ overflow: "hidden" }}
          />
        ) : undefined}
      </Box>
    </React.Fragment>
  );
}

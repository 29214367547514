import React from "react";
import { matchPath } from "react-router-dom";

import SidebarNavList from "components/sidebar/SidebarNavList";
import SidebarNavListItem from "components/sidebar/SidebarNavListItem";

const reduceChildRoutes = (props) => {
  const { currentRoute, depth, items, page } = props;

  if (page.children) {
    const open = page.href
      ? !!matchPath(
          {
            end: false,
            path: page.href,
          },
          currentRoute,
        )
      : false;

    items.push(
      <SidebarNavListItem
        badge={page.badge}
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        open={!!open}
        title={page.title}
      >
        <SidebarNavList depth={depth + 1} pages={page.children} />
      </SidebarNavListItem>,
    );
  } else {
    items.push(
      <SidebarNavListItem
        badge={page.badge}
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        title={page.title}
      />,
    );
  }

  return items;
};

export default reduceChildRoutes;

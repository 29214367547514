/* eslint-disable no-unused-vars */

import React, { useEffect } from "react";

import { ExtRefreshTokenMutation } from "components/gql/mutations/ExtRefreshToken";
import { CarListQuery } from "components/gql/queris/GetCarList";
import {
  CarRecsQuery,
  onCompletedRecs,
} from "components/gql/queris/GetCarRecs";
import { ChannelsQuery } from "components/gql/queris/GetChannels";
import { GetCreditsQuery } from "components/gql/queris/GetCredits";
import { GetCreditsHistoryQuery } from "components/gql/queris/GetCreditsHistory";
import { DepartmentsQuery } from "components/gql/queris/GetDepartments";
import {
  OrdersListQuery,
  onCompletedOrders,
} from "components/gql/queris/GetOrdersList";
import { GetPrices } from "components/gql/queris/GetPrices";
import { SelfDataQuery } from "components/gql/queris/GetSelfData";
import {
  SelfInfoQuery,
  onCompletedUser,
} from "components/gql/queris/GetSelfInfo";
import { GetData } from "contexts/DataContext";
import { GetAuthData } from "contexts/TAuthContext";
import {
  createTokenData,
  findInArray,
  getElementsPrices,
  getLocalStorageItem,
  getLocalToken,
  getParamToken,
  getParamTokenData,
  isIdentical,
  updateRecs,
  updateUser,
} from "data/functions";
import { isObjectEmpty } from "data/functions";
import { gqlReducer } from "data/globals";
import { GetBasket } from "./BasketContext";
import { GetCInfo } from "./CompanyContext";

export const GQLQueryContext = React.createContext();

function GQLQueryProvider({ children }) {
  const { dataObj, pushDataArr, setDataObj } = GetData();
  const [state, dispatch] = React.useReducer(gqlReducer, {});
  const { setLogined, setToken, setTokenData, token, tokenData } =
    GetAuthData();
  const fwq = GetAuthData();

  const [selfDataQuery] = SelfDataQuery();

  window.setTok = function (str) {
    setToken(str);
  };

  // #region Queries

  // #region GET_SELF_INFO
  const [selfInfoQuery] = SelfInfoQuery();

  useEffect(() => {
    if (token) selfInfoQuery();
    // eslint-disable-next-line
  }, [token]);
  // #endregion

  // #region COMPANY_LIST
  DepartmentsQuery(); //onCompleted is needed
  // #endregion

  // #region COMPANY_CHANNELS
  ChannelsQuery();
  // #endregion

  // #region CAR_LIST

  const [carRefetch] = CarListQuery();

  useEffect(() => {
    if (!isObjectEmpty(token)) {
      carRefetch();
      getSelfData(token);
    }
    //   // eslint-disable-next-line
  }, [token]);

  async function getSelfData(token) {
    let { data, error } = await selfDataQuery({
      variables: {
        token: token,
      },
    });
    if (data) {
      setDataObj(data?.auth_getSelfData?.clientID, "clientID");
      return true;
    }
    if (error) {
      return false;
    }
    return false;
  }

  // #endregion

  // #region ORDERS_LIST
  const [ordersListQuery, ordersListVars] = OrdersListQuery();

  useEffect(() => {
    if (!isObjectEmpty(token)) {
      ordersListQuery();
    }
    // eslint-disable-next-line
  }, [token]);

  // #region CAR_RECOMMENDATIONS_LIST
  const [recQuery, recVars] = CarRecsQuery();

  useEffect(() => {
    if (!isObjectEmpty(dataObj?.carIds) && !isObjectEmpty(token)) {
      dataObj?.carIds?.forEach((carID) => {
        updateRecs(recQuery, carID, {
          dataObj,
          pushDataArr,
        });
      });
    }
    // eslint-disable-next-line
  }, [dataObj?.carIds]);

  // #endregion

  // #region GET_CREDITS
  GetCreditsQuery(); //onCompleted is needed
  // #endregion

  // #region CREDIT_HISTORY
  GetCreditsHistoryQuery(); //onCompleted is needed
  // #endregion

  // #endregion

  const [refreshMutation] = ExtRefreshTokenMutation();

  React.useEffect(() => {
    checkLocalStorage();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      checkTokenData(tokenData, token);
    }, 30000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [tokenData, token]);

  async function checkLocalStorage() {
    let urlToken = getParamToken();
    let dataT = getParamTokenData();
    let usedToken = token;
    let tokenStorage = getLocalToken();

    if (
      !isObjectEmpty(urlToken) &&
      isObjectEmpty(dataT) &&
      !isIdentical(urlToken, usedToken)
    ) {
      await setTokenData({});
      checkAndSetToken(urlToken);
      return;
    }

    if (isObjectEmpty(dataT)) dataT = tokenData;
    if (isObjectEmpty(dataT)) dataT = getLocalStorageItem("tokenData");

    if (!isObjectEmpty(dataT)) {
      checkTokenData(dataT);
    } else if (tokenStorage) {
      checkAndSetToken(tokenStorage);
      return;
    } else {
      setTokenData({});
    }
  }

  async function checkToken(token) {
    let { data, error } = await selfDataQuery({
      variables: {
        token: token,
      },
    });
    if (data) {
      return true;
    }
    if (error) {
      return false;
    }
    return false;
  }

  async function checkAndSetToken(token, dontSet = false) {
    if (!token) return;
    let success = await checkToken(token);
    if (success) {
      // console.log("Успешная проверка на активность токена");
      if (!dontSet) setToken(token);
    } else {
      setLogined(false);
    }
  }

  async function checkAndSetTokenData(tokenD, dontSet = false) {
    if (isObjectEmpty(tokenD)) return;
    let success = await checkToken(tokenD?.accessToken);
    if (success) {
      // console.log("Успешная проверка на активность токена");
      if (!dontSet) setTokenData(tokenD);
    } else {
      setLogined(false);
    }
  }

  async function checkTokenData(data, t = "") {
    if (!isObjectEmpty(data)) {
      let active =
        new Date(data?.accessTokenExpires) > new Date(Date.now()) || false;
      let activeSoon =
        new Date(data?.accessTokenExpiresSoon) > new Date(Date.now()) || false;
      let activeRefresh =
        new Date(data?.refreshTokenExpires) > new Date(Date.now()) || false;
      if (active || (active && !activeSoon)) {
        if (!activeSoon) {
          refreshToken(data?.refreshToken, true);
        } else {
          checkAndSetTokenData(data);
        }
        return;
      } else if (activeRefresh) {
        refreshToken(data?.refreshToken, true);
        return;
      }
    } else {
      checkAndSetToken(t, true);
    }
  }

  async function refreshToken(rToken, del) {
    if (rToken) {
      let { data } = await refreshMutation({
        variables: {
          input: {
            token: rToken,
          },
        },
      });
      if (
        data?.auth_extRefreshToken.code === 200 &&
        data?.auth_extRefreshToken?.data?.accessToken
      ) {
        // checkAndSetToken(data?.auth_extRefreshToken?.data?.accessToken);
        let obj = createTokenData(
          data?.auth_extRefreshToken?.data?.accessToken,
          data?.auth_extRefreshToken?.data?.expiresIn,
          data?.auth_extRefreshToken?.data?.refreshToken,
          data?.auth_extRefreshToken?.data?.refreshExpiresIn,
        );

        setTokenData(obj);
      }

      if (data?.auth_extRefreshToken.code !== 200 && del) {
        // setToken(null);
        setTokenData({});
      }
    }
  }

  const contextValue = {
    state: state,
  };

  const [pricesQuery] = GetPrices();
  const { cInfo } = GetCInfo();
  const { basket, sectionItemAdd } = GetBasket();

  React.useEffect(() => {
    if (!isObjectEmpty(basket?.services)) {
      getElementsPrices(
        pricesQuery,
        basket?.services,
        {
          dataObj,
          setDataObj,
        },
        { cInfo },
        { sectionItemAdd },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataObj?.usedCar]);

  return (
    <GQLQueryContext.Provider value={contextValue}>
      {children}
    </GQLQueryContext.Provider>
  );
}
export const GetGQLContext = () => {
  try {
    const context = React.useContext(GQLQueryContext);
    return context;
  } catch {
    return "";
  }
};

export { GQLQueryProvider };

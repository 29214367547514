import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";
import { GetAuthData } from "contexts/TAuthContext";
import { dateToString } from "data/functions";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { Timer } from "./Timer";

export default function QRBlock(props) {
  const { token } = GetAuthData();

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "0.4rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <QRCode
          style={{
            maxWidth: "256px",
            width: "100%",
            height: "auto",
            background: "white",
            padding: "0.4rem",
          }}
          value={token}
        />
      </Box>
    </React.Fragment>
  );
}

function Countdown(seconds) {
  const [timeLeft, setTimeLeft] = useState(seconds);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((t) => t - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return timeLeft;
}

export function QRDialog(props) {
  const { tokenData } = GetAuthData();
  const [active, setActive] = React.useState(
    new Date(tokenData?.accessTokenExpires) > new Date(Date.now()) || undefined,
  );
  const [time, setTime] = React.useState();
  const [expiryTimestamp, setExpiryTimestamp] = React.useState();

  React.useEffect(() => {
    setExpiryTimestamp(new Date(tokenData?.accessTokenExpiresSoon));
    setActive(
      new Date(tokenData?.accessTokenExpires) > new Date(Date.now()) ||
        undefined,
    );
  }, [tokenData]);

  return (
    <React.Fragment>
      <Dialog
        maxWidth="sm"
        onClose={(e) => props?.setOpen(false)}
        open={props?.open}
      >
        <DialogContent>
          <Box sx={{ margin: "16px 24px" }}>
            <QRBlock />
          </Box>
          <Typography sx={{ textAlign: "center" }} variant="h6">
            Покажите QR-код, чтобы подтвердить списание бонусов или получить
            заказ
          </Typography>
          {active !== undefined && active && (
            <Timer
              expiryTimestamp={expiryTimestamp}
              setRemainingTime={setTime}
              variant="qr"
            />
          )}
          {active !== undefined && !active && (
            <Typography
              sx={{ textAlign: "center", paddingTop: "12px", color: "red" }}
              variant="body1"
            >
              Не действителен
            </Typography>
          )}
        </DialogContent>

        <Button
          onClick={(e) => props?.setOpen(false)}
          sx={{ margin: "0.4rem" }}
          variant="outlined"
        >
          ОК
        </Button>
      </Dialog>
    </React.Fragment>
  );
}

import { useMutation } from "@apollo/client";
import { SEARCH_DNS } from "components/gql/gql_queries";
import { consoleBeauty } from "data/functions";

export function SearchDnsMutation() {
  const [mutation, variables] = useMutation(SEARCH_DNS, {
    onCompleted: (data) => {
      if (
        data?.acc_searchDnsHostLink.code === 200 &&
        data?.acc_searchDnsHostLink?.data?.companyUUID
      ) {
        consoleBeauty(
          "Найдена компания по домену\n" +
            data?.acc_searchDnsHostLink?.data?.companyUUID,
          "GraphQL",
          "darkgreen",
        );
      } else {
        consoleBeauty(
          "SearchDns: " + data?.acc_searchDnsHostLink?.message,
          "GraphQL",
          "red",
        );
        sessionStorage.setItem(
          "error_" + new Date(Date.now()).toISOString(),
          data?.acc_searchDnsHostLink?.message,
        );
      }
    },
    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
    },
  });
  return [mutation, variables];
}

import React from "react";

import { IconButton } from "@mui/material";

import { Call } from "@mui/icons-material";
import { formatPhone } from "data/functions";

export function CallButton(props) {
  return (
    <IconButton
      aria-label="Позвонить"
      onClick={(e) => {
        if (props?.phone) {
          let phone = "+" + formatPhone(props?.phone);
          window.location.href = `tel:${phone}`;
        }
      }}
    >
      <Call />
    </IconButton>
  );
}

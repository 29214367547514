import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Rating,
  TextField,
} from "@mui/material";

import { GetAlertData } from "contexts/AlertContext";
import { GetData } from "contexts/DataContext";

export const ReviewDialog = (props) => {
  const [mark, setMark] = React.useState(0);
  const [text, setText] = React.useState("");

  const { setAlert } = GetAlertData();
  const { dataObj } = GetData();

  return (
    <Dialog
      PaperProps={{
        style: {
          minWidth: "60vw",
        },
      }}
      onClose={(e) => props.setOpen(false)}
      open={props.open}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {props?.title}
        <DialogContentText sx={{ marginTop: "1rem" }}>
          Оцените нашу работу!
        </DialogContentText>

        <Rating
          onChange={(event, newValue) => {
            setMark(newValue);
          }}
          precision={0.5}
          size="large"
          sx={{ marginBottom: "2rem" }}
          value={mark}
        />

        <TextField
          fullWidth
          label="Комментарий"
          maxRows={dataObj?.deviceWidth?.isMobile ? 4 : undefined}
          mb={2}
          mt={2}
          multiline
          name="comment"
          onChange={(e) => setText(e.target.value)}
          rows={dataObj?.deviceWidth?.isMobile ? undefined : 4}
          sx={{ margin: "0.3rem 0" }}
          type="text"
          value={text}
          variant={dataObj?.deviceWidth?.isMobile ? "standard" : "outlined"}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setText("");
            setMark(0);
            props.setOpen(false);
          }}
          variant="outlined"
        >
          Отмена
        </Button>
        <Button
          onClick={(e) => {
            console.log({ text: text, mark: mark });
            setText("");
            setMark(0);
            props.setOpen(false);
            setAlert("Функционал в разработке. \nОтзыв не сохранен", "warning");
          }}
          variant="outlined"
        >
          Отправить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

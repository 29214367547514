import styled from "@emotion/styled";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Rating,
  Typography,
} from "@mui/material";
import React from "react";

import { isObjectEmpty } from "data/functions";

const ChatMessage = styled.div`
  margin: 30px;
  text-align: ${(props) => props.position};
`;

const ChatMessageInner = styled.div`
  display: inline-block;
`;

const ChatMessageBubble = styled.div`
  display: inline-block;
  margin-right: auto;
  background: #dddddd1f;
  border-radius: 12px;
  padding: ${(props) => props.theme.spacing(6)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 25%);
`;

const ChatMessageBubbleName = styled(Typography)`
  font-weight: 600;
`;

export const ReviewsDialog = (props) => {
  const yourElementRef = React.useRef(null);
  React.useEffect(() => {
    if (yourElementRef.current) {
      yourElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <Dialog
      PaperProps={{
        style: {
          width: "90vw",
          maxWidth: "90vw",
          minHeight: "90vh",
        },
      }}
      onClose={(e) => props.setOpen(false)}
      open={props.status}
    >
      <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
        Отзывы
      </DialogTitle>
      <DialogContent sx={{ padding: 10, overflowY: "auto", width: "100%" }}>
        {!isObjectEmpty(props.reviews) &&
          props.reviews.map((element, index) => (
            <ChatMessage
              key={index}
              position={index % 2 === 0 ? "left" : "right"}
              ref={yourElementRef}
            >
              <ChatMessageInner>
                <ChatMessageBubble>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                      flexWrap: "nowrap",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gridGap: "0.5rem",
                    }}
                  >
                    <ChatMessageBubbleName variant="body1">
                      {element.name}
                    </ChatMessageBubbleName>

                    <Rating
                      defaultValue={parseInt(element.count)}
                      precision={0.5}
                      readOnly
                      size="small"
                    />
                  </Box>
                  <Typography variant="body2">{element.text}</Typography>
                </ChatMessageBubble>
              </ChatMessageInner>
            </ChatMessage>
          ))}
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          onClick={(e) => {
            props.setOpen(false);
          }}
          variant="outlined"
          // color="primary"
        >
          ОК
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import React from "react";

import { IconButton } from "@mui/material";
import { ReviewsDialog } from "pages/departments/ReviewDialog";

import { ForumOutlined } from "@mui/icons-material";

export function ReviewsButton(props) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <IconButton
        aria-label="Отзывы"
        onClick={(e) => {
          if (props?.reviews && props?.reviews?.length > 0) setOpen(true);
        }}
      >
        <ForumOutlined />
      </IconButton>
      <ReviewsDialog reviews={props?.reviews} setOpen={setOpen} status={open} />
    </React.Fragment>
  );
}

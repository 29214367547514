import { HelpOutline, ShoppingBasket } from "@mui/icons-material";
import {
  Badge,
  Button,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { GetPrices } from "components/gql/queris/GetPrices";
import { SelectedBlock } from "components/registration/SelectedBlock";
import { GetBasket } from "contexts/BasketContext";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export function RegistrationBlock(props) {
  const { dataObj } = GetData();
  const { getPrice, prices, registration, sections } = GetBasket();
  const [totalPrice, setTotalPrice] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [pricesQuery] = GetPrices();
  const navigate = useNavigate();

  React.useEffect(() => {
    let carID = dataObj?.usedCar?.car?.id || "all";
    let total = 0;
    if (!isObjectEmpty(registration?.services)) {
      registration?.services.forEach((el) => {
        let priceObj = getPrice(el?.id, carID, pricesQuery);
        total = total + (priceObj?.salePrice ? priceObj?.salePrice : 0);
      });
    }
    setTotalPrice(total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registration, dataObj?.usedCar, prices]);

  if (
    isObjectEmpty(registration?.recommendations) &&
    isObjectEmpty(registration?.services) &&
    isObjectEmpty(registration?.gifts)
  ) {
    return (
      <Button
        color="inherit"
        fullWidth
        onClick={() => navigate("/basket")}
        // sx={{ textTransform: "none" }}
        variant="outlined"
      >
        <Badge
          color="primary"
          invisible={isObjectEmpty(dataObj.currentBasketCount)}
          sx={{
            "& .MuiBadge-badge": {
              height: "8px",
              width: "8px",
              minWidth: "8px",
            },
          }}
          variant="dot"
        >
          <ShoppingBasket />
        </Badge>
      </Button>
    );
  } else
    return (
      <Table
        size="small"
        sx={{
          width: "100%",
          "& .MuiTableCell-root": {
            padding: props?.size === "small" ? "0!important" : undefined,
          },

          // props?.size === "small"
        }}
      >
        <TableBody>
          {!isObjectEmpty(
            (dataObj?.recsArray ? dataObj?.recsArray : {})[
              dataObj?.usedCar?.car?.id
            ],
          ) &&
            !isObjectEmpty(registration?.recommendations) && (
              <SelectedBlock
                array={registration?.recommendations || []}
                label="Выбранные рекомендации"
                section={sections?.recommendations}
                size={props?.size}
              />
            )}
          {!isObjectEmpty(registration?.services) && (
            <SelectedBlock
              array={registration?.services || []}
              label="Выбранные услуги"
              section={sections?.services}
              size={props?.size}
            />
          )}
          {dataObj?.presentAccrue && !isObjectEmpty(registration?.gifts) && (
            <SelectedBlock
              array={registration?.gifts || []}
              label="Бонусные услуги"
              section={sections?.gifts}
              size={props?.size}
            />
          )}
          {totalPrice > 0 && (
            <TableRow>
              <TableCell align="right" colSpan={1}>
                Итого:
              </TableCell>
              {props?.size !== "small" && (
                <TableCell align="right" sx={{ padding: 0, minWidth: "80px" }}>
                  {totalPrice ? totalPrice + " ₽" : 0}
                </TableCell>
              )}
              <TableCell
                align="center"
                sx={{ padding: "2px !important" }}
                width={20}
              >
                <IconButton
                  edge="end"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  size="small"
                  sx={{ margin: 0, padding: 2 }}
                >
                  <HelpOutline sx={{ width: "0.875em", height: "0.875em" }} />
                </IconButton>
                <Popover
                  PaperProps={{
                    style: {
                      padding: "0.4rem",
                    },
                  }}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  onClose={() => setAnchorEl(null)}
                  open={Boolean(anchorEl)}
                >
                  Указана примерная стоимость. Обратитесь в автосервис для
                  уточнения.
                </Popover>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
}

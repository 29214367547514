import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { GetData } from "contexts/DataContext";
import { getCarName } from "data/functions";
import RecList from "pages/recs/RecList";
import React from "react";

export const RecsDialog = (props) => {
  const { dataObj } = GetData();

  return (
    <Dialog
      PaperProps={{
        style: {
          width: "90vw",
          maxWidth: "90vw",
          minHeight: "90vh",
        },
      }}
      onClose={() => props?.setOpen(false)}
      open={props.status}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => props?.setOpen(false)}
      >
        Рекомендации
        <br /> {getCarName(dataObj?.usedCar)}
      </DialogTitle>
      <DialogContent sx={{ padding: 0, overflowY: "auto" }}>
        <RecList />
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          onClick={() => {
            props.setOpen(false);
          }}
          variant="outlined"
          // color="primary"
        >
          ОК
        </Button>
      </DialogActions>
    </Dialog>
  );
};

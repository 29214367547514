import { useLazyQuery } from "@apollo/client";
import { ORDERS_LIST } from "components/gql/gql_queries";
import { GetData } from "contexts/DataContext";
import { GetAuthData } from "contexts/TAuthContext";
import { consoleBeauty, isIdentical, isObjectEmpty } from "data/functions";

export function OrdersListQuery() {
  const { dataObj, setDataObj } = GetData();
  const { token } = GetAuthData();
  const [refetch, variables] = useLazyQuery(ORDERS_LIST, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    skip: !token,
    onCompleted: (data) => {
      onCompletedOrders(data, { dataObj, setDataObj });
    },
    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
    },
  });
  return [refetch, variables];
}

export function onCompletedOrders(data, { dataObj, setDataObj }, set = true) {
  let newArr = (!isObjectEmpty(data?.ordersList) ? data?.ordersList : []).sort(
    (a, b) => {
      let dateA = new Date(a.docDate || 0);
      let dateB = new Date(b.docDate || 0);
      if (dateA < dateB) return 1;
      else return -1;
    },
  );

  if (!isIdentical(newArr, dataObj?.orders)) {
    consoleBeauty("Получен список документов", "GraphQL", "darkgreen");
    setDataObj(newArr, "orders");
  }
}

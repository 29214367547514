import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Link } from "@mui/material";

export function ColoredButton(props) {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => {
        if (props.navigateOnClick) navigate(props.navigateOnClick);
      }}
      sx={{
        justifyContent: "flex-end",
        // margin: "0 0.5rem 0.5rem 0.5rem",
        width: props.width ? props.width : "unset",
        border: props.borderColor
          ? `1px solid ${props.borderColor}`
          : undefined,
      }}
      size="small"
      component={Link}
      to={props.path}
      color={props.color}
      variant={props.variant ? props.variant : "contained"}
      startIcon={props.icon}
    >
      {props.text}
    </Button>
  );
}

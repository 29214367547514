import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { RejectRecsMutation } from "components/gql/mutations/RejectRecs";
import { CarRecsQuery } from "components/gql/queris/GetCarRecs";
import { RejectReasonsQuery } from "components/gql/queris/GetRejectReasons";
import { GetBasket } from "contexts/BasketContext";
import { GetData } from "contexts/DataContext";
import { findInArray } from "data/functions";
import { updateRecs } from "data/functions";

export const RejectDialog = (props) => {
  const [reason, setReason] = React.useState("");
  const { dataObj, pushDataArr } = GetData();
  const { basket } = GetBasket();
  const rejectReasonsQuery = RejectReasonsQuery();
  const [rejectRecommendation, rejectRecommendationVars] = RejectRecsMutation();
  const [recQuery] = CarRecsQuery();

  async function reject() {
    if (reason) {
      let carID = dataObj?.usedCar?.car?.id;
      let arr = [];
      basket?.recommendations.forEach((element) => {
        if (!findInArray(arr, element.id)) arr = [...arr, element.id];
      });
      let { data } = await rejectRecommendation({
        variables: {
          input: {
            carID: carID,
            reasonID: reason,
            recommendationID: arr,
            userID: dataObj?.clientID,
          },
        },
      });
      if (data?.recommendationReject?.code === 200) {
        updateRecs(recQuery, carID, { dataObj, pushDataArr });
        setReason("");
        props.handleClose();
      } else {
        return false;
      }
    } else return false;
  }

  return (
    <Dialog onClose={props.handleClose} open={props.open}>
      <DialogTitle>Причина отказа</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Для отказа от выданных рекомендаций введите пожалуйста причину отказа.
        </DialogContentText>

        <FormControl fullWidth>
          {!rejectReasonsQuery?.loading && (
            <Select
              id="reason"
              onChange={(e) => {
                setReason(e.target.value);
              }}
              value={reason}
            >
              {(rejectReasonsQuery?.data?.rejectReasons
                ? rejectReasonsQuery?.data?.rejectReasons
                : []
              ).map((el, i) => (
                <MenuItem key={i} value={el.id}>
                  {el.name}
                </MenuItem>
              ))}
            </Select>
          )}
          {rejectReasonsQuery?.loading && (
            <Select disabled id="reason" value="Загрузка..">
              <MenuItem value="Загрузка..">Загрузка..</MenuItem>
            </Select>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} variant="outlined">
          Отмена
        </Button>
        <LoadingButton
          color="error"
          loading={rejectRecommendationVars?.loading}
          onClick={(e) => {
            reject();
          }}
          sx={{ textDecoration: "underline" }}
          // variant="outlined"
        >
          Отказаться
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

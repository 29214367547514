import { useQuery } from "@apollo/client";

import { REJECT_REASONS } from "components/gql/gql_queries";
import { consoleBeauty } from "data/functions";

export function RejectReasonsQuery() {
  const query = useQuery(REJECT_REASONS, {
    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
    },
  });

  return query;
}

import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import FindBlock from "pages/services/FindBlock";
import { FolderPage } from "pages/services/FolderPage";

export const ServicesDialog = (props) => {
  return (
    <Dialog
      PaperProps={{
        style: {
          width: "90vw",
          maxWidth: "90vw",
          minHeight: "90vh",
        },
      }}
      onClose={() => props?.setOpen(false)}
      open={props.status}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => props?.setOpen(false)}
      >
        Услуги и товары
      </DialogTitle>
      <DialogContent
        sx={{
          padding: 0,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <FindBlock />

        <FolderPage />
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          onClick={(e) => {
            props.setOpen(false);
          }}
          variant="outlined"
          // color="primary"
        >
          Выбрать
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { GetBasket } from "contexts/BasketContext";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";
import React from "react";

export const PresentsDialog = (props) => {
  const { dataObj } = GetData();

  const { inBasket, sectionItemAdd, sectionItemDelete, sections } = GetBasket();

  function handleChecked(value) {
    let find = inBasket(value, sections.gifts);
    if (!find) sectionItemAdd("basket", value, sections.gifts);
    else sectionItemDelete("basket", value, sections.gifts);
  }

  return (
    <Dialog
      PaperProps={{
        style: {
          width: "90vw",
          maxWidth: "90vw",
          minHeight: "90vh",
        },
      }}
      onClose={() => props?.setOpen(false)}
      open={props.status}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => props?.setOpen(false)}
      >
        Бонусные услуги
      </DialogTitle>
      <DialogContent
        sx={{
          padding: 0,
          overflowY: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ overflow: "auto" }}>
          {!isObjectEmpty(dataObj?.presentAccrue) &&
            dataObj?.presentAccrue.map((value, index) => {
              const labelId = `checkbox-list-label-${value}`;
              let check = inBasket(value, sections.gifts);
              return (
                <ListItem
                  disablePadding
                  key={index}
                  onClick={(e) => {
                    handleChecked(value);
                  }}
                  sx={{ paddingLeft: "16px" }}
                >
                  <ListItemButton dense role={undefined}>
                    <ListItemIcon>
                      <Checkbox
                        checked={check || false}
                        disableRipple
                        edge="start"
                        tabIndex={-1}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={
                        <Typography
                          variant={
                            dataObj?.deviceWidth?.isMobile ? "caption" : "body2"
                          }
                        >
                          {value.name}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant={
                            dataObj?.deviceWidth?.isMobile ? "caption" : "body2"
                          }
                        >
                          {value.price ? (
                            value.price > value.salePrice ? (
                              <>
                                {dataObj?.deviceWidth?.isMobile ? <br /> : ""}
                                <strike>{value.price}</strike> ₽{" "}
                                {value.salePrice} ₽
                              </>
                            ) : (
                              <>
                                {dataObj?.deviceWidth?.isMobile ? <br /> : ""}
                                {value.salePrice} ₽
                              </>
                            )
                          ) : (
                            ""
                          )}
                        </Typography>
                      }
                      sx={{ margin: 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          onClick={(e) => {
            props.setOpen(false);
          }}
          variant="outlined"
          // color="primary"
        >
          Выбрать
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { Divider, Typography } from "@mui/material";
import { GetData } from "contexts/DataContext";
import { BoxFlexRow } from "data/styleGlobals";
import React from "react";

export default function ParamBlock(props) {
  const { dataObj } = GetData();
  return (
    <React.Fragment>
      <BoxFlexRow>
        <Typography
          variant={dataObj?.deviceWidth?.isMobile ? "body2" : "body1"}
        >
          {props.title}
        </Typography>
        <Typography
          sx={{ marginLeft: "4px" }}
          variant={dataObj?.deviceWidth?.isMobile ? "body2" : "body1"}
        >
          {props.text}
        </Typography>
      </BoxFlexRow>
      <Divider />
    </React.Fragment>
  );
}

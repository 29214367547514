import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./App.css";

const el = document.getElementById("app");

window.addEventListener("resize", function () {
  const doc = document.documentElement;
  doc.style.setProperty("--app-height", `${window.innerHeight}px`);
});

createRoot(el).render(<App />);

import { Box } from "@mui/material";
import { PageTitle } from "components/PageTitle";
import { GetData } from "contexts/DataContext";
import { getCarName } from "data/functions";
import { isObjectEmpty } from "data/functions";
import ProfileButtons from "pages/cars//info/ProfileButtons";
import ProfileImage from "pages/cars//info/ProfileImage";
import ProfileParams from "pages/cars/info/ProfileParams";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

export default function CarProfile() {
  let location = useLocation();
  const [carID] = useState(location.state?.carID || {});
  const [car, setCar] = useState({});
  const [sold, setSold] = useState(undefined);
  const { dataObj } = GetData();

  useEffect(() => {
    refreshProfile();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    refreshProfile();
    // eslint-disable-next-line
  }, [dataObj?.carArrWithSold]);

  const params = useParams();
  function refreshProfile() {
    const carVIN = params.id;
    if (dataObj?.carArrWithSold) {
      dataObj?.carArrWithSold.forEach((item) => {
        if (item?.car?.id === carID || item?.car?.vin === carVIN) {
          setCar(item);
          setSold(item.sold);
        }
      });
    }
  }
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <PageTitle title={getCarName(car)} />
        <Box sx={{ overflow: "auto" }}>
          {car && !isObjectEmpty(car) && (
            <Box>
              <ProfileImage car={car} />
              <Box sx={{ margin: "1.5rem" }}>
                <ProfileParams car={car} sold={sold} />
                <ProfileButtons car={car} />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
}

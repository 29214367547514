
//https://yandex.ru/dev/maps/jsbox/2.1/placemark/
//https://yandex.ru/dev/maps/jsbox/2.1/balloon_html/
// https://yandex.ru/dev/maps/jsbox/2.1/list_box_layout

import { Box, FormControl, MenuItem, Paper, Select } from "@mui/material";
import {
  YMaps,
  withYMaps,
} from "@pbe/react-yandex-maps";
import { GetCInfo } from "contexts/CompanyContext";
import { isIdentical, isObjectEmpty } from "data/functions";
import * as React from "react";

export function MapBlock(props) {


  const [ok, setOK] = React.useState(false);
  const { cInfo } = GetCInfo();
  const [city, setCity] = React.useState();
  const [departmentArray, setDepartmentArray] = React.useState(cInfo?.departments || []); 
  const handleChange = (event: SelectChangeEvent) => {
    setCity(event.target.value);
  };

  React.useEffect(() => {
    if (!isIdentical(departmentArray, cInfo?.departments)) {
      setDepartmentArray(cInfo?.departments);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cInfo?.departments])

    React.useEffect(() => {
    if (!isIdentical(city, cInfo?.regions) && !isObjectEmpty(cInfo?.regions) ) {
      if (isObjectEmpty(cInfo?.preferedRegion)) {
        setCity(cInfo?.regions[0]?.name);
      } else {
        setCity(cInfo?.preferedRegion.name);
      }
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cInfo?.regions, cInfo?.preferedRegion])
  
  const mapComp = React.useMemo(() => {
    return ({ setOK, ymaps }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [map, setMap] = React.useState(null);
      
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const mapRef = React.useRef(null);

      // eslint-disable-next-line react-hooks/rules-of-hooks
      React.useEffect(() => {
        if (!ymaps || !mapRef.current) {
          return;
        }
        ymaps.ready(() => {
          let dep = (departmentArray ?? []).find((element) => {
            if (element?.region === city) return true;
            else return false;
          })
          let mapN = new ymaps.Map(
            mapRef.current,
            {
              center: [dep[0]?.locationLatitude, dep[0]?.locationLongitude] ||  [departmentArray[0]?.locationLatitude, departmentArray[0]?.locationLongitude] || [0, 0],
              controls: ['zoomControl'],
              zoom: 10,
            },
            {
              geolocationControlFloat: "right",
              // restrictMapArea: true,
              suppressMapOpenBlock: true,
              zoomControlPosition: { left: 10, top: 50 },
              zoomControlSize: "small",
            },
          );
          setMap(mapN);
        });

        // return () => (canceled = true);
      }, [ymaps]);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      React.useEffect(() => {
        if (map && !isObjectEmpty(departmentArray)) {
          departmentArray.forEach((element) => {
            let placemark = new ymaps.Placemark(
              [element?.locationLatitude, element?.locationLongitude],
              {
                balloonContent: element.adress,
                balloonContentFooter: `<a href="/departments/${element.ID}">Показать</>`,
                balloonContentHeader: element.name,
                iconContent: "",
              },
              {
                balloonAutoPan: false,
                balloonCloseButton: true,
                openBalloonOnClick: true
                
              },
            );
            if (element?.region === city || isObjectEmpty(city)) {
                map.geoObjects.add(placemark);
              } 
            
            // if (departmentArray.length === 1) placemark.balloon.open();
          });
           if (map.geoObjects.getBounds())
          map
            .setBounds(map.geoObjects.getBounds(), { checkZoomRange: true })
            .then(function () {
              if (map.getZoom() > 15) map.setZoom(15);
            });
        if (map && map.controls.get("zoomControl"))
          map.controls.add("zoomControl", {
            float: "none",
            position: {
              bottom: "50px",
              right: "30px",
            },
            size: "small",
          });
          setOK(true);
          
        }
        if (map) {
          if ((map.behaviors.isEnabled('scrollZoom'))) {
              map.behaviors.disable('scrollZoom');
          }
          if ((map.behaviors.isEnabled('drag'))) {
              map.behaviors.disable('drag');
          }
          if ((map.behaviors.isEnabled('dblClickZoom'))) {
              map.behaviors.disable('dblClickZoom');
          }
          if ((map.behaviors.isEnabled('multiTouch'))) {
              map.behaviors.disable('multiTouch');
          }
        }
       
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [map, city]);

      return <div ref={mapRef} style={{ width: "98%", height: "240px", display:"flex", justifyContent: "center" }} />;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city]);

  const Map = React.useMemo(() => {
    return withYMaps(mapComp, true, ["Map", "Placemark"]);    
  }, [mapComp]);


  function haveBaloon(array) {
    if (isObjectEmpty(array)) return false;
    let finded = false; 
     departmentArray.forEach((element) => {
      if (!isObjectEmpty(element?.locationLatitude) && !isObjectEmpty(element?.locationLongitude)) {
        finded = true;
      }
    })
    return finded;

  }

  return (
    <React.Fragment>
      {!isObjectEmpty(departmentArray) && haveBaloon(departmentArray) && (
        <Box sx={{ position: "relative" }}>
  
          <YMaps
            onError={(e)=>console.log(e)}
            preload={true}
            query={{
              apikey: "814fa36a-a896-4ffa-971d-1cd05a72aa65",
              load: "package.full",
            }}
   
          >
            <Map setOK={setOK}/>
          </YMaps>
          {ok && (cInfo?.regions ?? []).length > 1 && <Paper sx={{ position: "absolute", top: 2, left: 2 }} >
            <FormControl size="small" sx={{  minWidth: 120 }} variant="standard" >
              <Select
              disableUnderline={true}
              id="select-city"
              // label="Город"
                labelId="select-city-label"
                onChange={handleChange}
                sx={{ padding: "4px 0px 0px 12px"}}
                // size="small"
                value={city}
                // variant="standard"
              >
                {!isObjectEmpty(cInfo?.regions) && (cInfo?.regions ?? []).map((el, ind) => (
                  <MenuItem key={ind} value={el?.name}>{el?.name}</MenuItem>
                ))}

              </Select>
            </FormControl>
          </Paper>}
        </Box>
      )}
    </React.Fragment>
  );
}
import { useLazyQuery } from "@apollo/client";
import { GET_SELF_INFO } from "components/gql/gql_queries";
import { GetData } from "contexts/DataContext";
import { GetAuthData } from "contexts/TAuthContext";
import { consoleBeauty, isIdentical, isObjectEmpty } from "data/functions";

export function SelfInfoQuery() {
  const { token } = GetAuthData();
  const { dataObj, setDataObj } = GetData();
  const [refetch, variables] = useLazyQuery(GET_SELF_INFO, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    skip: !token,
    onCompleted: (data) => {
      onCompletedUser(data, { dataObj, setDataObj });
    },
    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
    },
  });
  return [refetch, variables];
}

export function onCompletedUser(data, { dataObj, setDataObj }) {
  if (
    !isObjectEmpty(data.userRead) &&
    !isIdentical(data.userRead, dataObj?.clientInfoArray)
  ) {
    consoleBeauty(
      "Запрос информации пользователя по токену. Успех.",
      "GraphQL",
      "darkgreen",
    );
    setDataObj(data.userRead, "clientInfoArray");
    if (
      data.userRead.name.fullName &&
      data.userRead.name.fullName !== null &&
      data.userRead.name.fullName !== dataObj.clientName
    ) {
      setDataObj(data.userRead.name.fullName, "clientName");
    }
    if (
      data.userRead.phone !== null &&
      data.userRead.phone instanceof Array &&
      data.userRead.phone.length > 0 &&
      (data.userRead.phone?.toString() !== dataObj.clientPhones?.toString() ||
        data.userRead.phone[0] !== dataObj.usedPhone)
    ) {
      // setDataObj(data.userRead.phone, "clientPhones");
      setDataObj(data.userRead.phone[0], "usedPhone");
    }
  }
}

import React, { useState } from "react";

import { Add } from "@mui/icons-material/";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";
import CarListItem from "pages/cars/list/CarListItem";
import ListTitle from "pages/cars/list/ListTitle";
import { Outlet, useNavigate } from "react-router-dom";

export default function CarList() {
  const { dataObj } = GetData();
  const [carArr, setCarArr] = useState(dataObj?.carArr || []);
  return (
    <React.Fragment>
      <ListTitle setCarArr={setCarArr} title="Мои автомобили" />
      <List>
        {!isObjectEmpty(carArr) &&
          carArr.map((el, index) => {
            return (
              <CarListItem el={el} index={index} key={index} navigate={true} />
            );
          })}
        <CarAddBlock />
      </List>
      <Outlet />
    </React.Fragment>
  );
}

export function CarAddBlock() {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <ListItem onClick={() => navigate("/cars/add")}>
        <ListItemAvatar>
          <Avatar
            sx={{
              background: "transparent",
              boxShadow: "inset 0px 0px 2px #00000026",
            }}
          >
            <Add color="dimblue" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Добавить автомобиль" />
      </ListItem>
    </React.Fragment>
  );
}

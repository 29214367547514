import { useMutation } from "@apollo/client";
import { EXT_REFRESH_TOKEN } from "components/gql/gql_queries";
import { consoleBeauty } from "data/functions";

export function ExtRefreshTokenMutation() {
  const [mutation, variables] = useMutation(EXT_REFRESH_TOKEN, {
    onCompleted: (data) => {
      if (
        data?.auth_extRefreshToken.code === 200 &&
        data?.auth_extRefreshToken?.data?.accessToken
      ) {
        consoleBeauty(
          "Успешно обновлен токен\n" +
            data?.auth_extRefreshToken?.data?.accessToken,
          "GraphQL",
          "darkgreen",
        );
      }
    },
    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
    },
  });
  return [mutation, variables];
}

import React from "react";

import { PhotoCamera } from "@mui/icons-material";
import { Box, Button, IconButton, Paper } from "@mui/material";
import { PageTitle } from "components/PageTitle";
import { DecodeVinMutation } from "components/gql/mutations/DecodeVin";
import { GetCarReportQuery } from "components/gql/queris/GetCarReport";
import { GetMarksQuery } from "components/gql/queris/GetMarks";
import { GetModelsQuery } from "components/gql/queris/GetModels";
import { GetData } from "contexts/DataContext";
import {
  decodePlate,
  decodeVin,
  findInArray,
  isIdentical,
  isObjectEmpty,
} from "data/functions";
import ButtonsBlock from "pages/cars/add/ButtonsBlock";
import ImageBlock from "pages/cars/add/ImageBlock";
import SelectBlock from "pages/cars/add/SelectBlock";
import FieldBlock from "pages/cars/add/fields/FieldBlock";
import FieldYear from "pages/cars/add/fields/FieldYear";
import DecodeGrz from "./DecodeGrz";
import DecodeSts from "./DecodeSts";
import ResponseButtonWithDialog from "./ResponseButtonWithDialog";

export default function CarAdd() {
  const { dataObj, pushDataArr } = GetData();

  const marksQuery = GetMarksQuery();
  const [marka, setMarka] = React.useState({});
  const [markName, setMarkName] = React.useState("");
  const [markText, setMarkText] = React.useState("");
  const [markList, setMarkList] = React.useState(dataObj?.markList || []);

  const [modelsQuery, modelsVars] = GetModelsQuery();
  const [model, setModel] = React.useState({});
  const [modelName, setModelName] = React.useState("");
  const [modelText, setModelText] = React.useState("");
  const [modelList, setModelList] = React.useState([]);

  const [mileage, setMileage] = React.useState(0);

  const [plateQuery] = GetCarReportQuery();
  const [plate, setPlate] = React.useState("");
  const [plateText, setPlateText] = React.useState("");
  const [plateDecodeData, setPlateDecodeData] = React.useState({});
  const [plateData, setPlateData] = React.useState({});
  const [fileData, setFileData] = React.useState();
  const [vinQuery] = DecodeVinMutation();
  const [vin, setVin] = React.useState("");
  const [vinText, setVinText] = React.useState("");
  const [vinData, setVinData] = React.useState({});

  const [year, setYear] = React.useState(null);
  const [yearText, setYearText] = React.useState("");

  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    if (!isObjectEmpty(dataObj?.markList)) {
      setMarkList(dataObj?.markList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataObj?.markList]);

  React.useEffect(() => {
    if (marka) {
      setModel("");
      setModelName("");
      setModelList([]);
    }
    // console.log(mark);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marka]);

  React.useEffect(() => {
    if (markName && markName !== marka?.name) {
      let markTmp = findInArray(markList, markName, "name");
      if (markTmp) {
        setMarka(markTmp);
        getModels(markTmp?.id);
      } else {
        setMarka({});
        setModelList([]);
      }
      setModel({});
      setModelName("");
    }
    if (modelName && modelName !== model?.name) {
      let modelTmp = findInArray(modelList, modelName, "name");
      if (modelTmp) {
        setModel(modelTmp);
      } else {
        setModel({});
        // setModelList([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markName, modelName]);

  async function getModels(markID, modelID = "") {
    if (!markID) return false;
    let marka = findInArray(markList, markID, "id");
    if (!marka) {
      return false;
    }
    let { data, error } = await modelsQuery({
      variables: { markId: markID },
    });
    if (data) {
      let modelsArray = data?.exch_getModels?.models || [];
      modelsArray.sort((a, b) => {
        return a?.name > b?.name ? 1 : a?.name === b?.name ? 0 : -1;
      });
      setModelList(modelsArray);
      let newModel = {};
      if (modelID) {
        let model = findInArray(modelsArray, modelID, "id");
        if (model) {
          newModel = model;
        }
      }
      setModel(newModel);
      if (newModel?.name) setModelName(newModel?.name);
      return true;
    }
    if (error) {
      setModel({});
      setModelList([]);
      setModelText("Не удалось получить модели.");
    }
    return false;
  }

  async function checkVin(v) {
    if (v.length < 17) return;
    let [marks, models, error] = await decodeVin(v, vinQuery);
    if (error) {
      setMarkText("");
      setModelText("");
      return;
    }
    let vinRes = {};
    if (!isObjectEmpty(marks)) {
      let markHelper = marks.map((el, ind) => {
        if (ind <= 5) return el.name;
        else return "";
      });
      let markTxt = markHelper.join("; ");
      if (marks.length > 5) markTxt = markTxt + " и др.";
      setMarkText(markTxt);
      setMarkName(marks[0]?.name);
      let markaVin = findInArray(markList, marks[0]?.id, "id");
      if (markaVin && !isIdentical(marka, markaVin)) setMarka(markaVin);
      await getModels(marks[0]?.id, models[0]?.id ?? "");
      marks.forEach((element) => {
        vinRes = {
          ...vinRes,
          marks: [...(vinRes.marks ?? []), element?.name],
        };
      });
    }

    if (!isObjectEmpty(models)) {
      let modelHelper = models.map((el, ind) => {
        if (ind <= 5) return el.name;
        else return "";
      });
      let modelTxt = modelHelper.join("; ");
      if (models.length > 5) modelTxt = modelTxt + " и др.";
      setModelText(modelTxt);
      models.forEach((element) => {
        vinRes = {
          ...vinRes,
          models: [...(vinRes.models ?? []), element?.name],
        };
      });
    }
    setVinData(vinRes);
  }

  async function checkPlate(p) {
    if (p.length < 7) return;
    // reset(false);
    let [plateRes, error] = await decodePlate(p, plateQuery);
    if (error) {
      setPlateData(error);
      return;
    }
    if (!isObjectEmpty(plateRes)) {
      setPlateData(plateRes);
      if (plateRes?.vin && !isIdentical(vin, plateRes?.vin)) {
        setVin(plateRes?.vin);
        checkVin(plateRes?.vin);
      }
      if (plateRes?.year && !isIdentical(year, plateRes?.year)) {
        setYearText(plateRes?.year);
        setYear(new Date("" + plateRes?.year));
      }
      if (
        plateRes?.brandNormalized &&
        !isIdentical(markText, plateRes?.brandNormalized)
      ) {
        setMarkText(plateRes?.brandNormalized);
      }
      if (
        plateRes?.brandOriginal &&
        !isIdentical(modelText, plateRes?.brandOriginal)
      ) {
        setModelText(plateRes?.brandOriginal);
      }
    }
  }

  function reset(full = true) {
    setMarka({});
    setMarkName("");
    setModel({});
    setModelName("");

    setVin("");
    setVinText("");
    setMarkText("");
    setModelText("");

    setMarkList(dataObj?.markList);
    setModelList([]);
    setPlateText("");
    setYearText("");
    setError(false);
    if (full) {
      setPlate("");
      setYear(null);
      setMileage("");
    }
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <PageTitle title="Добавление автомобиля" />
        <Box sx={{ overflow: "auto" }}>
          <FieldBlock
            component={
              <ResponseButtonWithDialog
                data2={vinData}
                dialogTitle2="Расшифровка VIN"
                data={fileData}
                dialogTitle="Свидетельство о регистрации ТС"
              />
            }
            helperText={vinText ? vinText : undefined}
            icon={
              <DecodeSts
                setMarkText={setMarkText}
                setModelText={setModelText}
                setPlate={setPlate}
                setPlateText={setPlateText}
                setVin={setVin}
                setVinText={setVinText}
                setYear={setYear}
                fileData={fileData}
                setFileData={setFileData}
                setYearText={setYearText}
                checkVin={checkVin}
              />
            }
            label="VIN"
            length={24}
            onBlurFunc={checkVin}
            required={error}
            setValue={setVin}
            title="VIN"
            value={vin}
          />
          <FieldBlock
            component={
              <ResponseButtonWithDialog
                data={plateData}
                dialogTitle="Расшифровка гос. номера"
                data2={plateDecodeData}
                dialogTitle2="Распознанный по фото гос. номер"
              />
            }
            helperText={plateText ? plateText : undefined}
            icon={
              <DecodeGrz
                setPlate={setPlate}
                setPlateText={setPlateText}
                checkPlate={checkPlate}
                setPlateDecodeData={setPlateDecodeData}
              />
            }
            label="regNumber"
            length={12}
            onBlurFunc={checkPlate}
            setValue={setPlate}
            title="Гос. номер"
            value={plate}
          />

          <SelectBlock
            array={markList}
            helperText={markText ? markText : undefined}
            label="marka"
            loading={marksQuery?.loading}
            required={error}
            setInputValue={setMarkName}
            setSecondValue={getModels}
            setValue={setMarka}
            title="Марка"
            value={markName}
          />
          <SelectBlock
            array={modelList}
            helperText={modelText ? modelText : undefined}
            label="model"
            loading={modelsVars?.loading}
            required={error}
            setInputValue={setModelName}
            setValue={setModel}
            title="Модель"
            value={modelName}
          />
          <FieldYear
            helperText={yearText ? yearText : undefined}
            setYear={setYear}
            year={year}
          />
          <FieldBlock
            label="mileage"
            setValue={setMileage}
            title="Зафиксированный пробег"
            type="number"
            value={mileage === 0 ? "" : mileage}
          />

          <ImageBlock marka={marka} model={model} modelName={modelName} />
          <ButtonsBlock
            markList={markList}
            marka={marka}
            mileage={mileage}
            model={model}
            modelList={modelList}
            plate={plate}
            reset={reset}
            setError={setError}
            vin={vin}
            year={year}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
}

import styled from "@emotion/styled";
import { Receipt } from "@mui/icons-material";
import {
  Card as MuiCard,
  CardContent,
  Grid as MuiGrid,
  Tooltip,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { dateToString, isObjectEmpty, parseRFC3339 } from "data/functions";
import React from "react";
import { AtSign, Gift, Phone } from "react-feather";
const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Grid = styled(MuiGrid)(spacing);
const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)};

  svg {
    width: 14px;
    height: 14px;
  }
`;
const Spacer = styled.div(spacing);

function AboutItem(props) {
  return (
    <Grid alignItems="center" container direction="row">
      <Grid item>
        <Tooltip title={props.title}>
          <AboutIcon>{props.icon}</AboutIcon>
        </Tooltip>
      </Grid>
      <Grid item>{props.value}</Grid>
    </Grid>
  );
}

export function About(props) {
  let birthdayDate = undefined;
  if (
    props?.info?.birthday !== "0001-01-01 00:00:00 +0000 UTC" &&
    props?.info?.birthday !== "0001-01-01T00:00:00+00:00" &&
    !isObjectEmpty(props?.info?.birthday)
  ) {
    birthdayDate = dateToString(parseRFC3339(props?.info?.birthday));
  }
  if (
    isObjectEmpty(birthdayDate) &&
    isObjectEmpty(props?.info?.email) &&
    isObjectEmpty(props?.info?.phone)
  ) {
    return;
  }

  return (
    <React.Fragment>
      <Card sx={{ margin: "0.5rem", background: "transparent", flexGrow: 1 }}>
        <CardContent>
          <Typography gutterBottom variant="h6">
            Данные
          </Typography>

          <Spacer mb={4} />
          {!isObjectEmpty(props?.info?.email) && (
            <AboutItem
              icon={<AtSign />}
              title="Электронная почта"
              value={props?.info?.email}
            />
          )}
          {!isObjectEmpty(birthdayDate) && (
            <AboutItem
              icon={<Gift />}
              title="День рождения"
              value={birthdayDate}
            />
          )}

          <Grid alignItems="start" container direction="column">
            {!isObjectEmpty(props?.info?.phone) &&
              props?.info?.phone.map((phone, index) => (
                <AboutItem
                  icon={<Phone />}
                  key={index}
                  title={
                    props?.info?.phone.length > 1
                      ? "Номера телефонов"
                      : "Номер телефона"
                  }
                  value={phone}
                />
              ))}
          </Grid>

          <AboutItem
            icon={<Receipt />}
            title="Способ передачи ФЧ"
            value={"Не указан"}
          />
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

import { Autocomplete, Box, TextField } from "@mui/material";
import { GetData } from "contexts/DataContext";
import React from "react";

export default function SelectBlock(props) {
  const { dataObj } = GetData();
  return (
    <React.Fragment>
      <Box sx={{ margin: "0.5rem 1rem" }}>
        <Autocomplete
          autoSelect={true}
          clearOnEscape
          disableClearable={true}
          freeSolo
          fullWidth
          getOptionKey={(option) => {
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.id) {
              return option.id;
            }
          }}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (!option) {
              return "";
            }
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.name) {
              return option.name;
            }
            // Regular option
            return option.title;
          }}
          id={props?.label}
          onChange={(event, newValue) => {
            if (event.type !== "blur") {
              if (typeof newValue !== "string") {
                props?.setValue(newValue);
              } else {
                props?.setValue("");
              }
              try {
                props?.setSecondValue(newValue?.id);
              } catch (error) {}
              try {
                props?.setThirdValue(newValue);
              } catch (error) {}
            }
          }}
          options={
            props?.loading ? ["Загрузка.."] : props?.array ? props.array : []
          }
          // renderOption={(props, option) => {
          //   const { key, ...optionProps } = props;
          //   return (
          //     <li key={option?.id} {...optionProps}>
          //       {option?.name}
          //     </li>
          //   );
          // }}
          renderInput={(params) => {
            console.log();
            return (
              <TextField
                {...params}
                variant="standard"
                // value={props?.value}
                helperText={props.helperText ? props.helperText : undefined}
                label={props?.title}
                onBlur={(e) => {
                  props?.setInputValue(e.target.value);
                }}
                sx={{
                  fontSize: dataObj?.deviceWidth?.isMobile
                    ? "0.875rem"
                    : "1rem",
                }}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            );
          }}
          value={props?.value ? props?.value : ""}
        />
      </Box>
    </React.Fragment>
  );
}

import { Visibility, VisibilityOff } from "@mui/icons-material/";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { PageTitle } from "components/PageTitle";
import { GetData } from "contexts/DataContext";
import React, { useEffect, useState } from "react";

export default function ListTitle(props) {
  const { dataObj } = GetData();
  const [hideSold, setHideSold] = useState(false);

  useEffect(() => {
    if (hideSold) props?.setCarArr(dataObj?.carArrWithSold);
    else props?.setCarArr(dataObj?.carArr);
    // eslint-disable-next-line
  }, [hideSold, dataObj?.carArrWithSold, dataObj?.carArr]);

  return (
    <Grid container spacing={0} sx={{ alignItems: "center" }}>
      <Grid item xs={10}>
        <PageTitle alignStart={true} title={props.title} />
      </Grid>
      <Grid item xs={2}>
        <IconButton
          disableRipple
          onClick={() => {
            setHideSold(!hideSold);
          }}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            borderRadius: "200px",
          }}
        >
          {hideSold && (
            <Tooltip title="Скрыть проданные">
              <Visibility />
            </Tooltip>
          )}

          {!hideSold && (
            <Tooltip title="Показать все">
              <VisibilityOff />
            </Tooltip>
          )}
          {!dataObj?.deviceWidth?.isMobile && (
            <Typography sx={{ fontSize: "12px" }} variant="body1">
              {hideSold ? "Скрыть проданные" : "Показать все"}
            </Typography>
          )}
        </IconButton>
      </Grid>
    </Grid>
  );
}

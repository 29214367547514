import { Receipt } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { GetData } from "contexts/DataContext";
import React from "react";

export default function PromocodeField(props) {
  const { dataObj } = GetData();
  return (
    <TextField
      InputProps={{
        style: {
          fontSize: dataObj?.deviceWidth?.isMobile ? "0.875rem" : "1rem",
        },
        startAdornment: (
          <InputAdornment position="start">
            <Receipt />
          </InputAdornment>
        ),
      }}
      fullWidth
      label="Промокод"
      mb={2}
      mt={2}
      name="promocode"
      onChange={(e) => props.setPromocode(e.target.value)}
      placeholder="Введите промокод..."
      sx={{ margin: "0.875rem 0" }}
      type="text"
      value={props.promocode}
      variant="standard"
    />
  );
}

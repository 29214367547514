import styled from "@emotion/styled";
import { Box, Fade, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  dateToString,
  hex2rgb,
  parseRFC3339,
  translateUrgency,
} from "data/functions";
import React from "react";

import { GetBasket } from "contexts/BasketContext";

export const MainBox = styled(Box)`
  position: relative;
  padding: ${(props) => (props?.padding ? props?.padding : "3px 0 3px 55px")};
  text-align: left;
  display: flex;
  flex-direction: column;
  margin: ${(props) => (props?.margin ? props?.margin : "0.6em 0")};
  border: 1px solid
    ${(props) => hex2rgb(props.theme?.custom?.telegramColors?.text_color, 0.1)};
  &:hover {
    background-color: rgb(0 0 0 / 5%);
  }
  border-radius: 8px;
`;

export const UrgencyBox = styled(Box)`
  position: absolute;
  top: 0;
  left: ${(props) => (props?.left ? props?.left : "40px")};
  width: 10px;
  height: 100%;
  background: ${(props) =>
    props.critical === 2
      ? "red"
      : props.theme?.custom?.telegramColors?.button_color};
  border: 1px solid rgb(0 0 0 / 35%);
  box-shadow:
    rgba(9, 30, 66, 0.25) 0px 2px 3px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
`;

export const BlockWithPseudo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0.4rem;
  cursor: ${(props) => (props?.cursor ? props?.cursor : "pointer")};
  &:before,
  &:after {
    content: ${(props) => (props?.disablePseudo ? "none" : '""')};
    position: absolute;
    top: 50%;
  }
  &:before {
    left: 12px;
    width: 18px;
    height: 18px;
    margin: -9px 0 0;
    background: #f7f7f7;
    box-shadow: 0 0 1px grey;
  }
  &:after {
    z-index: 1;

    left: 14px;
    width: 14px;
    height: 14px;
    margin: -7px 0 0;
    opacity: 0;
    background: ${(props) =>
      props.critical === 2
        ? "red"
        : props.theme?.custom?.telegramColors?.button_color
          ? props.theme?.custom?.telegramColors?.button_color
          : "#007aff"};
    transform: translate3d(-40px, 0, 0) scale(0.5);
    transition:
      opacity 0.25s ease-in-out,
      transform 0.25s ease-in-out;
  }
`;

const InputBlock = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  display: none;

  &:checked + div {
    &:after {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
`;

export default function RecBlock(props) {
  const { inBasket, sectionItemAdd, sectionItemDelete, sections } = GetBasket();

  function handleChecked(value) {
    let find = inBasket(value, sections.recommendations);
    if (!find) sectionItemAdd("basket", value, sections.recommendations);
    else sectionItemDelete("basket", value, sections.recommendations);
  }
  let check = inBasket(props.recItem, sections.recommendations);

  return (
    <MainBox
      key={props.index}
      onClick={(e) => {
        handleChecked(props.recItem);
      }}
    >
      <Tooltip
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 300 }}
        arrow
        placement={"top-start"}
        slotProps={{
          popper: {
            sx: {
              [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                {
                  marginBottom: "0.4rem",
                  marginLeft: "-4px",
                },
            },
          },
        }}
        title={translateUrgency(props.recItem.urgency)}
      >
        <UrgencyBox critical={props.recItem.urgency} />
      </Tooltip>
      <InputBlock
        checked={check || false}
        onChange={(e) => {}} //if delete it will give an error
        type="checkbox"
      />
      <BlockWithPseudo critical={props.recItem.urgency}>
        <Typography variant={props?.isMobile ? "caption" : "body2"}>
          {props.recItem.text}
        </Typography>
        <Typography sx={{ opacity: 0.5, fontSize: "0.9em" }}>
          {dateToString(parseRFC3339(props.recItem.createdAt))}
        </Typography>
      </BlockWithPseudo>
    </MainBox>
  );
}

import React, { useEffect } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
import { GetPrices } from "components/gql/queris/GetPrices";
import { GetData } from "contexts/DataContext";
import {
  assignedObject,
  findInArray,
  getElementsPrices,
  getLocalStorageItem,
  isIdentical,
  setLocalStorageItem,
} from "data/functions";
import { isObjectEmpty } from "data/functions";
import { actions } from "data/globals";
import { GetCInfo } from "./CompanyContext";

export const BasketContext = React.createContext();

export const reducer = (state, action) => {
  switch (action.type) {
    case "contextSet": {
      if (!isIdentical(state[action.paramName], action.paramValue))
        return { ...state, [action.paramName]: action.paramValue };
      else return state;
    }
    case actions.checkArray: {
      return state;
    }
    case "delete": {
      return { ...state, [action.object]: {} };
    }
    case "sectionDelete": {
      if (action.paramName)
        return {
          ...state,
          [action.object]: {
            ...state[action.object],
            [action.paramName]: [],
          },
        };
      else return state;
    }
    case "sectionItemDelete": {
      let newValue = undefined;
      let paramValue = state[action.object][action.paramName] || [];
      newValue = [...paramValue].filter(
        (item) => item?.id !== action.paramValue?.id,
      );
      if (!isIdentical(paramValue, newValue) && newValue !== undefined) {
        return {
          ...state,
          [action.object]: {
            ...state[action.object],
            [action.paramName]: newValue,
          },
        };
      } else return state;
    }
    case "sectionItemAdd": {
      let newValue = undefined;
      let paramValue = state[action.object][action.paramName] || [];
      let stringifiedObj = JSON.stringify(action.paramValue);
      if (!paramValue.some((item) => JSON.stringify(item) === stringifiedObj))
        newValue = [...paramValue, action.paramValue];
      if (!isIdentical(paramValue, newValue) && newValue !== undefined)
        return {
          ...state,
          [action.object]: {
            ...state[action.object],
            [action.paramName]: newValue,
          },
        };
      else return state;
    }
    default:
      return state;
  }
};

export const initialState = {
  basket: {},
  prices: {},
  registration: {},
};

export function CheckSize(theme) {
  return useMediaQuery(theme.breakpoints.up("md"), {
    noSsr: true,
  });
}

function BasketProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { dataObj, setDataObj } = GetData();

  const value = {
    basket: state.basket,
    contextDelete: React.useCallback(
      (obj) =>
        dispatch({
          object: obj,
          type: "delete",
        }),
      [],
    ),
    contextSet: React.useCallback(
      (param, value) =>
        dispatch({
          paramName: param,
          paramValue: value,
          type: "contextSet",
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
    prices: state.prices,
    registration: state.registration,
    sectionDelete: React.useCallback(
      (obj, param) =>
        dispatch({
          object: obj,
          paramName: param,
          type: "sectionDelete",
        }),
      [],
    ),
    sectionItemAdd: React.useCallback(
      (obj, value, param) =>
        dispatch({
          object: obj,
          paramName: param,
          paramValue: value,
          type: "sectionItemAdd",
        }),
      [],
    ),
    sectionItemDelete: React.useCallback(
      (obj, value, param) =>
        dispatch({
          object: obj,
          paramName: param,
          paramValue: value,
          type: "sectionItemDelete",
        }),
      [],
    ),
    sections: {
      gifts: "gifts",
      recommendations: "recommendations",
      services: "services",
    },
    count: () => {
      let count = 0;
      Object.keys(state?.basket).forEach((key) => {
        count = count + (state?.basket[key] ?? []).length;
      });
      return count;
    },
    getPrice: (id, carID = "all") => {
      if (!id) return undefined;
      let priceObj = findInArray(value?.prices[carID], id, "serviceID");
      if (!isObjectEmpty(priceObj)) return priceObj;
    },
    inBasket: (value, param) =>
      !isObjectEmpty(
        (state?.basket[param] ? state?.basket[param] : []).find(
          (o) => o.id === value?.id,
        ),
      ),
    isEmptyContext: (obj) =>
      isObjectEmpty((state[obj] ?? {})?.recommendations) &&
      isObjectEmpty((state[obj] ?? {})?.services) &&
      isObjectEmpty((state[obj] ?? {})?.gifts),
  };

  React.useEffect(() => {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      let val = undefined;
      if (key.includes("basket_"))
        try {
          val = getLocalStorageItem(key);
          if (!isObjectEmpty(val)) {
            value?.contextSet(key.replace("basket_", ""), val);
          }
        } catch {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    Object.keys(state).forEach((param) => {
      let val = "";
      try {
        val = getLocalStorageItem("basket_" + param);
      } catch (error) {}
      if (
        val !== state[param] &&
        state[param] !== null &&
        state[param] !== undefined
      ) {
        setLocalStorageItem(
          "basket_" + param.replace("basket_", ""),
          state[param],
        );
      }
    });

    setDataObj(value.count(), "currentBasketCount");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // React.useEffect(() => {

  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (value?.basket?.recommendations)
      value?.sectionDelete("basket", value?.sections?.recommendations);
    if (value?.registration?.recommendations)
      value?.sectionDelete("registration", value?.sections?.recommendations);
    // eslint-disable-next-line
  }, [dataObj?.usedCar, dataObj?.recsArray]);

  return (
    <BasketContext.Provider value={value}>{children}</BasketContext.Provider>
  );
}
export const GetBasket = () => {
  const context = React.useContext(BasketContext);
  return context;
};

export { BasketProvider };

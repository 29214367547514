import React from "react";

import {
  isIdentical,
  isObjectEmpty,
  setLocalStorageItem,
} from "data/functions";

export const TAuthContext = React.createContext();

export const reducer = (state, action) => {
  switch (action.type) {
    case "SET_TOKEN": {
      if (!isObjectEmpty(action.paramValue)) {
        if (!isIdentical(state?.token, action.paramValue)) {
          setLocalStorageItem("token", action.paramValue);
          return { ...state, isLogined: true, token: action.paramValue };
        } else return state;
      } else {
        localStorage.removeItem("token");
        if (state?.isLogined !== false || state?.token !== "")
          return { ...state, isLogined: false, token: "" };
        else return state;
      }
    }
    case "SET_DATA": {
      if (!isObjectEmpty(action.paramValue)) {
        if (
          !isIdentical(state?.tokenData, action.paramValue) ||
          !isIdentical(state?.token, action.paramValue.accessToken)
        ) {
          setLocalStorageItem("tokenData", action.paramValue);
          setLocalStorageItem("token", action.paramValue.accessToken);
          return {
            ...state,
            isLogined: true,
            token: action.paramValue.accessToken,
            tokenData: action.paramValue,
          };
        } else return state;
      } else {
        localStorage.removeItem("tokenData");
        localStorage.removeItem("token");
        if (
          !isObjectEmpty(state?.tokenData) ||
          !isObjectEmpty(state?.token) ||
          state?.isLogined !== false
        )
          return { ...state, isLogined: false, token: "", tokenData: {} };
        else return state;
      }
    }
    case "SET_LOGINED": {
      if (action.paramValue === state?.isLogined) return state;
      if (action.paramValue) return { ...state, isLogined: action.paramValue };
      else {
        setLocalStorageItem("token", "");
        return { ...state, isLogined: action.paramValue, token: "" };
      }
    }
    default:
      return state;
  }
};

function TAuthProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, {});

  const value = {
    isLogined: state.isLogined,
    setLogined: React.useCallback(
      (value) =>
        dispatch({
          paramValue: value,
          type: "SET_LOGINED",
        }),
      [],
    ),

    setToken: React.useCallback(
      (value) =>
        dispatch({
          paramValue: value,
          type: "SET_TOKEN",
        }),
      [],
    ),
    setTokenData: React.useCallback(
      (value) =>
        dispatch({
          paramValue: value,
          type: "SET_DATA",
        }),
      [],
    ),
    token: state.token,
    tokenData: state.tokenData,
  };

  // if (isObjectEmpty(state.token) && !isObjectEmpty(tokenStorage)) {
  //   dispatch({
  //     paramValue: tokenStorage,
  //     type: "SET_TOKEN",
  //   });
  // }

  return (
    <TAuthContext.Provider value={value}>{children}</TAuthContext.Provider>
  );
}
export const GetAuthData = () => {
  const context = React.useContext(TAuthContext);
  return context;
};

export { TAuthProvider };

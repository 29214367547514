import { GetData } from "contexts/DataContext";
import { showMainScreen } from "data/mobapp_functions";
import { DesktopMainPage } from "pages/main/desktop/DesktopMain";
import { MobileMainPage } from "pages/main/mobile/MobileMain";
import React, { useState } from "react";

import { HeaderRow } from "components/HeaderRow";
import { MapBlock } from "components/MapBlock";
import { PromotionSlider } from "components/sliders/PromotionSlider";
import { GetCInfo } from "contexts/CompanyContext";
import { isObjectEmpty } from "data/functions";
import { PromoList } from "pages/promotions/list/PromoList";
function MainPage() {
  const { dataObj } = GetData();
  React.useEffect(() => {
    if (dataObj?.clientID) showMainScreen(dataObj?.clientID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      {dataObj?.deviceWidth?.isMobile ? (
        <MobileMainPage />
      ) : (
        <DesktopMainPage />
      )}
    </React.Fragment>
  );
}

export default MainPage;
